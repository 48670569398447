import './App.css';
import Home from './components/Home'
import {BrowserRouter,Routes,Route} from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Name">
          Serena Sizhe Hou
        </div>
        <div className="Nav-links">
          <div className="Link-box">
            <a href="work">work</a>
          </div>
          <div className="Link-box">
            <a href="fun">fun</a>
          </div>
          <div className="Link-box">
            <a href="about">about</a>
          </div>
          <div className="Link-box">
            <a href="resume">resume</a>
          </div>
        </div>
      </header>
      <BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/work" element={<Home />} />
          <Route path="/fun" />
          <Route path="/about" />
        </Routes>
      </BrowserRouter>
    </div>
    
  );
}

export default App;

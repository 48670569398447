import '../App.css';


function Home() {
    return (
        <div className="Home">
            <div className="Home-card">

            </div>
            <div className="Home-card">
                
            </div>
            <div className="Home-card">
                
            </div>
        </div>
    );
}


export default Home;